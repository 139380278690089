import React, { Component } from "react";
import OldTitles from "./oldTitles";
import AcceptedTitle from "./AcceptedTitle";
import "./End.css";

class End extends Component {
  constructor(props) {
    super(props);
    this.state = {
      possibleTitles: this.props.possibleTitles,
      previousTitles: [],
      currentTitle: this.props.possibleTitles[
        Math.floor(Math.random() * this.props.possibleTitles.length)
      ],
      promotionAccepted: false,
      negotiationPhase: false,
      negotiations: 0,
    };
  }

  renegotiate = (title) => {
    this.setState(
      {
        previousTitles: [...this.state.previousTitles, title],
        possibleTitles: [...this.state.possibleTitles].filter(
          (possibleTitle) => possibleTitle !== title
        ),
        negotiations: this.state.negotiations + 1,
      },
      () => {
        let newTitle = this.state.possibleTitles[
          Math.floor(Math.random() * this.state.possibleTitles.length)
        ];
        this.setState({ currentTitle: newTitle });
      }
    );
  };

  acceptTitle = () => {
    this.setState({ promotionAccepted: true });
  };

  selectOldTitle = (title) => {
    this.setState({ currentTitle: title });
    this.acceptTitle();
  };

  render() {
    const { promotionAccepted, previousTitles } = this.state;
    let currentTitle = this.state.currentTitle.split(",");
    let titleAcronym = currentTitle[0];
    let fullTitle = currentTitle[1];

    const renegotiateButton = (
      <div className="renegotiateContainer">
        {this.state.negotiations < 1 ? (
          <h3>Think something else would be a better fit?</h3>
        ) : (
          <h3>Still not Satisfied?</h3>
        )}
        <div
          className="button renegotiateBtn"
          onClick={() => {
            this.renegotiate(this.state.currentTitle);
          }}
        >
          NEGOTIATE
        </div>
      </div>
    );

    const titleNegotiation = (
      <div className="negotiationContainer QuizContainer">
        {this.state.negotiations > 0 ? (
          <h2 style={{ fontWeight: 600 }}>How does this sound?</h2>
        ) : (
          <h2>
            Congratulations on claiming your place in the{" "}
            <nobr>Home C-Suite!</nobr> We think you’re the perfect fit for…
          </h2>
        )}
        <h2 className="titleContainer">
          <span id="titleAcronym">{titleAcronym}</span> <br></br> {fullTitle}
        </h2>
        <div
          id="acceptCurrentTitleBtn"
          className="button"
          onClick={this.acceptTitle}
        >
          LET'S DO IT!
        </div>
        <div className="line"></div>

        {this.state.negotiations > 0 ? (
          <OldTitles
            onClick={this.selectOldTitle}
            previousTitles={previousTitles}
          />
        ) : (
          <div></div>
        )}

        {this.state.negotiations < 2 ? renegotiateButton : <div></div>}
      </div>
    );

    return !promotionAccepted ? (
      titleNegotiation
    ) : (
      <AcceptedTitle title={currentTitle} />
    );
  }
}

export default End;
