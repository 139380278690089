import React from "react";
import { Link } from "react-router-dom";
import "./Landing.css";
// import hch from "../assets/hch_logo.png"
import hch2x from "../assets/Needlepoint_Logo.png";
import Footer from "./Footer.jsx";

const Landing = () => {
  return (
    <div className="container landing">
      <div className="column flex-start">
        <div className="imgContainer">
          <img src={hch2x} alt="Home C-Suite Home Logo" />
        </div>
      </div>
      <div className="column">
        <main>
          <div className="desktop">
            <h1>
              Now that we’re WFH 24/7, the personal and professional are merging
              like never before.
            </h1>
            <p>
              We’ve gone from “Let’s take this offline” to always being online,
              from touching base to “Don’t touch your face!”, from core
              competencies to couch competencies.
            </p>
            <p>
              It’s time your title reflected your new leadership role during
              this merger. All you have to do to get it is fill out this quick
              survey!
            </p>
          </div>
          <div className="mobile">
            <h1>
              Now that we’re WFH 24/7, the personal and professional are merging
              like never before.
            </h1>
            <p>
              It’s time your title reflected your new leadership role during
              this merger. All you have to do to get it is fill out this quick
              survey!
            </p>
          </div>
          <div className="landingBtn">
            <Link to="/quiz" className="quizLink button pulse-button">
              {/* <span className="mobile">Get Promoted <span>></span></span> */}
              <span>
                Claim Your Home C-Suite Title <span>></span>
              </span>
            </Link>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Landing;
