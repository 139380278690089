import React, { Component } from "react";
import Question from "./Question.jsx";
import "./Quiz.css";
import Questions from "../Store/Questions";
import TitleBank from "../Store/TitleBank";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";

import End from "./End";

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      possibleTitles: [],
      questionNumber: "questionOne",
      questions: Questions,
      haveKids: false,
      End: false,
      questionHistory: [],
    };
  }

  goBack = () => {
    this.setState({
      questionNumber: this.state.questionHistory.pop(),
      possibleTitles: this.state.possibleTitles.slice(
        0,
        this.state.possibleTitles.length - 1
      ),
    });
  };

  nextQuestion = (question, option, nextQuestion) => {
    this.setState({
      questionHistory: [...this.state.questionHistory, question],
    });
    this.addTitle(question, option);

    if (this.state.questionNumber === "questionSeven") {
      this.setState({
        End: true,
        possibleTitles: this.state.possibleTitles.filter((el) => {
          if (el !== null && el !== undefined) {
            return el;
          }
          return false;
        }),
      });
    } else {
      this.setState({ questionNumber: nextQuestion });
    }
  };

  addTitle = (questionNumber, answer) => {
    let answerChosen;
    if (answer.includes(".")) {
      answerChosen = answer
        .split(".")[1]
        .replace(/[ ,.'!&-?/:]/g, "")
        .toUpperCase();
    } else {
      answerChosen = answer.replace(/[ ,.'!-?/:]/g, "").toUpperCase();
    }

    let title = this.getTitle(questionNumber, answerChosen);
    this.setState({ possibleTitles: [...this.state.possibleTitles, title] });
  };

  getTitle = (questionNumber, answer) => {
    if (
      (questionNumber === "haveKidsQuestion" && answer.includes("INFANT")) ||
      (questionNumber === "haveKidsQuestion" && answer.includes("TODDLER"))
    ) {
      this.setState({ haveKids: true });
    }

    let bucket;
    let title;
    try {
      if (
        questionNumber === "questionThree" ||
        questionNumber === "questionFour"
      ) {
        if (this.state.haveKids) {
          bucket = TitleBank[questionNumber]["HAVEKIDS"][answer];
        } else {
          bucket = TitleBank[questionNumber][answer];
        }
      } else {
        bucket = TitleBank[questionNumber][answer];
      }

      //newhobbies input
      if (bucket.length === 1) {
        title = bucket[0];
      } else {
        title = bucket[Math.floor(Math.random() * bucket.length)];
      }
    } catch {
      return null;
    }
    return title;
  };

  render() {
    const questionObj = this.state.questions[this.state.questionNumber];
    const question = questionObj["question"];
    const options = questionObj["options"];
    const imageUrl = questionObj["imageUrl"];
    const questionType = questionObj["questionType"];
    const nextQuestion = questionObj["nextQuestion"];
    const questionNumber = this.state.questionNumber;

    const EndContainer = (
      <React.Fragment>
        <Header></Header>
        <End possibleTitles={this.state.possibleTitles}></End>
        <Footer></Footer>
      </React.Fragment>
    );

    const QuizContainer = (
      <React.Fragment>
        <Header></Header>
        <div className="QuizContainer">
          <div className="container quiz">
            <div className="column">
              <Question
                questionNumber={questionNumber}
                question={question}
                options={questionType === "mc" ? options : null}
                nextQuestion={nextQuestion}
                next={this.nextQuestion}
              />
              {this.state.questionHistory.length > 0 ? (
                <div className="button" onClick={this.goBack} id="backBtn">
                  Go Back
                </div>
              ) : (
                <span></span>
              )}
            </div>
            <div className="column" id="imgColumn">
              <div className="imgContainer">
                <img
                  className="questionImage"
                  src={imageUrl}
                  alt="questionImage"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </React.Fragment>
    );

    return this.state.End ? EndContainer : QuizContainer;
  }
}

export default Quiz;
