import React from "react";
import "./OldTitles.css";

const OldTitles = (props) => {
  const previousTitles = props.previousTitles.map((prevTitle, index) => (
    <div className="oldtitle" key={index}>
      <h2 className="titleContainer">
        {prevTitle.split(",")[0]}
        <br></br>
        {prevTitle.split(",")[1]}
      </h2>
      <div
        onClick={() => {
          props.onClick(prevTitle);
        }}
        className="button acceptOldTitleBtn"
      >
        TAKE IT
      </div>
      <div className="line"></div>
    </div>
  ));
  return (
    <React.Fragment>
      <h3>Our Previous Offer Still Stands: </h3>
      <div className="oldTitlesContainer">{previousTitles}</div>
    </React.Fragment>
  );
};

export default OldTitles;
