import React, { Component } from "react";
import "./QuestionInput.css";

class QuestionInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hobby: "",
    };
  }

  handleChange = (e) => {
    this.setState({ hobby: e.target.value });
  };

  render() {
    return (
      <div className="hobbyQuestion">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            this.props.next(
              this.props.questionNumber,
              this.state.hobby,
              this.props.nextQuestion
            );
          }}
        >
          <input
            type="text"
            value={this.state.hobby}
            onChange={this.handleChange}
            className="questionOption"
          ></input>
          <input type="submit" className="button" value="Neat"></input>
        </form>
      </div>
    );
  }
}

export default QuestionInput;
