import React from "react";
import handWrittenLogo from "../assets/hch_handwriting_logo.png";
import "./HeaderFooter.css";

const Header = (props) => {
  return (
    <>
      <header>
        <a href="/">
          <img src={handWrittenLogo} alt="HCH"/>
        </a>
      </header>
    </>
  );
};

export default Header;
