import React from "react";
import umStudios from "../assets/um_studios_logo.png";
import Credits from "../assets/Credits.png";

import "./HeaderFooter.css";

const Footer = (props) => {
  return (
    <>
      <footer>
        <a href="https://www.umww.com/">
          <div id="um-studios-logo">
            <img src={umStudios} alt="UM Studios Logo" />
          </div>
        </a>

        <div id="credits">
          <span class="credits_text">
            Alex Delgado<br></br>Alfred Marks<br></br>Alex Tafur<br></br>Brian
            Riemer<br></br>Carrie Rossman<br></br>Jeff Chui<br></br>Rebecca
            Snyder<br></br>Troy Starwalt
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
