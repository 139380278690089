import React, { Component } from "react";
import "./AcceptedTitle.css";
import HCSHLogo from "../assets/Needlepoint_Logo_FinalImage.png";

class AcceptedTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedTitle: this.props.title,
      name: "",
      photoUploaded: false,
      headShotGenerated: false,
      image: "",
    };

    let { acceptedTitle } = this.state;

    this.handleFiles = handleFiles.bind(this);
    this.generateHeadShot = generateHeadShot.bind(this);

    function handleFiles(event) {
      const file = event.target.files[0];
      console.log(file);
      if (!file.type.match(/image.*/)) {
        alert("please upload your headshot in either jpg or png format");
      } else {
        if (this.state.headShotGenerated) {
          this.setState({ image: file });
          var img = document.createElement("img");
          img.name = this.state.name;
          img.onload = draw;
          img.src = window.URL.createObjectURL(file);
        } else {
          this.setState({ image: file, photoUploaded: true });
        }
      }
    }

    function generateHeadShot() {
      if (this.state.name === "" || this.state.image === "") {
        alert("Please input your name and upload a headshot");
      } else {
        this.setState({ headShotGenerated: true });
        const file = this.state.image;
        var img = document.createElement("img");
        img.name = this.state.name;
        img.onload = draw;
        img.src = window.URL.createObjectURL(file);

        document.getElementById("canvas2").style.display = "block";
        document.getElementById("nameInput").style.display = "none";
        document.getElementById("downloadLink").style.display = "inline-block";
        document.getElementById("file1").style.display = "none";
        document.getElementById("file2").style.display = "block";
        document.getElementById("generateBtn").style.display = "none";
      }
    }

    function draw() {
      var MAX_WIDTH = 600;
      var MAX_HEIGHT = 600;
      var width = this.width;
      var height = this.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      var canvas = document.getElementById("canvas");
      canvas.style.display = "none";
      var canvas2 = document.getElementById("canvas2");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.filter = "grayscale(100%)";
      ctx.drawImage(this, 0, 0, width, height);
      ctx.font = "bold 18px Montserrat";

      // ctx.fillText(this.name.toUpperCase(), width / 2, height - 100);

      var ctx2 = canvas2.getContext("2d");
      canvas2.width = width;
      canvas2.height = height;
      ctx2.drawImage(canvas, 0, 0, width, height);

      ctx2.fillStyle = "rgba(255, 255, 255, 0.8)";
      ctx2.fillRect(0, height - 65, width, 65);

      ctx2.fillStyle = "rgba(237, 28, 36, 0.8)";
      ctx2.fillRect(0, height - 92, width, 27);

      ctx2.strokeRect(0, 0, width, height);

      // Name

      // Title

      // .then(function () {
      ctx2.textAlign = "center";
      ctx2.fillStyle = "white";
      ctx2.font = "bold 18px Montserrat";

      ctx2.fillText(this.name.toUpperCase(), width / 2, height - 72);
      ctx2.textAlign = "center";
      ctx2.fillStyle = "black";
      ctx2.font = "bold 23px Montserrat";
      ctx2.fillText(acceptedTitle[0], width / 2, height - 37);
      ctx2.font = "bold 15px Montserrat";

      ctx2.fillText(acceptedTitle[1], width / 2, height - 15);
      // });

      var logo = new Image();
      logo.src = HCSHLogo;
      logo.onload = function () {
        ctx2.drawImage(logo, -5, -5, 140, 140);

        download(canvas2);
      };
    }
    function download(c) {
      let dl = document.getElementById("downloadLink");
      dl.href = c.toDataURL("image/jpg");
      dl.style.display = "block";
    }
  }

  onInputChange = (e) => {
    this.setState({ name: e.target.value });
  };

  componentDidMount() {
    var canvas2 = document.getElementById("canvas2");
    var ctx2 = canvas2.getContext("2d");
    ctx2.font = "bold 18px Montserrat";
    ctx2.fillText("fontloaded", 100, 40);
  }

  render() {
    let titleAcronym = this.state.acceptedTitle[0];
    let fullTitle = this.state.acceptedTitle[1];
    return (
      <div className="acceptedTitleContainer">
        <h2>Congratulations on your new title!</h2>
        <h1 className="titleContainer">
          <b style={{ fontSize: "1.8em", fontWeight: 600 }}>{titleAcronym}</b>
          <br></br>
          {fullTitle}
        </h1>
        <h3>
          Want a snazzy headshot<br></br>to announce it to the world?
        </h3>
        <label className="file" id="file1">
          <input
            type="file"
            aria-label="Upload Headshot"
            id="input1"
            name="headshot"
            accept="image/png, impage/jpeg, image/jpg"
            onChange={this.handleFiles}
          ></input>
          <span className="file-custom"></span>
        </label>
        <br></br>
        <canvas id="canvas"></canvas>
        <canvas className="headshot" id="canvas2"></canvas>
        <br></br>
        {this.state.photoUploaded ? (
          <React.Fragment>
            <div id="fontloader" style={{ fontFamily: "Montserrat" }}>
              Test gotham font
            </div>
            <input
              onChange={this.onInputChange}
              type="text"
              id="nameInput"
              placeholder="What's your name?"
              value={this.state.name}
            ></input>
            <div
              onClick={() => {
                this.generateHeadShot();
              }}
              id="generateBtn"
              className="button"
            >
              GENERATE
            </div>
            <div id="headshotBtnContainer">
              <label className="file" id="file2">
                <input
                  type="file"
                  aria-label="Upload Headshot"
                  id="input2"
                  name="headshot"
                  accept="image/png, impage/jpeg, image/jpg"
                  onChange={this.handleFiles}
                ></input>
                <span className="file-custom" id="file-custom2"></span>
              </label>
              <a href="/" download id="downloadLink">
                <div className="button">DOWNLOAD</div>
              </a>
            </div>
          </React.Fragment>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default AcceptedTitle;
