import Q1 from "../assets/frames/frames_Q1.png";
import Q2 from "../assets/frames/frames_Q2.png";
import Q3 from "../assets/frames/frames_Q3.png";
import Q4 from "../assets/frames/frames_Q4.png";
import Q5 from "../assets/frames/frames_Q5.png";
import Q6A from "../assets/frames/frames_Q6A.png";
import Q6B from "../assets/frames/frames_Q6B.png";
import Q6C from "../assets/frames/frames_Q6C.png";
import Q6D from "../assets/frames/frames_Q6D.png";
import Q6E from "../assets/frames/frames_Q6E.png";
import Q7 from "../assets/frames/frames_Q7.png";
import Q8 from "../assets/frames/frames_Q8.png";
import Q9 from "../assets/frames/frames_Q9.png";

const Questions = {
  questionOne: {
    question: "Do you have kids?",
    questionType: "mc",
    options: [
      { option: "A. Yes", nextQuestion: "haveKidsQuestion" },
      { option: "B. No", nextQuestion: "petsQuestion" },
    ],
    imageUrl: Q1,
  },
  haveKidsQuestion: {
    question: "How old are they?",
    questionType: "mc",

    options: [
      { option: "A. Infant", nextQuestion: "petsQuestion" },
      { option: "B. Toddler", nextQuestion: "petsQuestion" },
      { option: "C. Tween", nextQuestion: "petsQuestion" },
      { option: "D. Teen", nextQuestion: "petsQuestion" },
      { option: "E. Adult", nextQuestion: "petsQuestion" },
    ],
    imageUrl: Q1,
  },
  petsQuestion: {
    question: "What about pets?",
    questionType: "mc",

    options: [
      { option: "A. Cats", nextQuestion: "questionThree" },
      { option: "B. Dogs", nextQuestion: "questionThree" },
      { option: "C. Reptiles", nextQuestion: "questionThree" },
      { option: "D. Fish", nextQuestion: "questionThree" },
      { option: "E. Birds", nextQuestion: "questionThree" },
      { option: "F. Other", nextQuestion: "questionThree" },
      { option: "G. None", nextQuestion: "questionThree" },
    ],
    imageUrl: Q2,
  },
  questionThree: {
    question: "How much do you enjoy cooking while at home?",
    questionType: "mc",
    options: [
      { option: "A. Takeout all day, baby.", nextQuestion: "questionFour" },
      {
        option: "B. It's a chore, but I'll do it.",
        nextQuestion: "questionFour",
      },
      { option: "C. I don't mind it!", nextQuestion: "questionFour" },
      {
        option: "D. It's fun to try new recipes.",
        nextQuestion: "questionFour",
      },
      { option: "E. Getting my chef on!", nextQuestion: "questionFour" },
    ],
    imageUrl: Q3,
  },
  questionFour: {
    question: "How much do you enjoy exercising at home?",
    questionType: "mc",
    options: [
      { option: "A. Exercise? Who's that?", nextQuestion: "questionFive" },
      { option: "B. Not much at all.", nextQuestion: "questionFive" },
      { option: "C. Every so often.", nextQuestion: "questionFive" },
      { option: "D. More than I used to.", nextQuestion: "questionFive" },
      { option: "E. Making Gains Every Day.", nextQuestion: "questionFive" },
    ],
    imageUrl: Q4,
  },
  questionFive: {
    question: "What's your favorite form of entertainment while you're home?",
    questionType: "mc",
    options: [
      { option: "A. Games", nextQuestion: "gamesQuestion" },
      { option: "B. Movies", nextQuestion: "moviesQuestion" },
      { option: "C. Video Games", nextQuestion: "videoGamesQuestion" },
      { option: "D. TV", nextQuestion: "TVQuestion" },
      { option: "E. Books", nextQuestion: "booksQuestion" },
      { option: "F. Music", nextQuestion: "musicQuestion" },
      { option: "G. Social Media", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q5,
  },
  booksQuestion: {
    question: "Which of these books would you rather read?",
    questionType: "mc",
    options: [
      {
        option: "A. Lord of The Rings",
        nextQuestion: "questionSeven",
      },
      {
        option: "B. Murder On The Orient Express",
        nextQuestion: "questionSeven",
      },
      { option: "C. IT", nextQuestion: "questionSeven" },
      { option: "D. Becoming: Michelle Obama", nextQuestion: "questionSeven" },
      { option: "E. Crazy Rich Asians", nextQuestion: "questionSeven" },
      { option: "F. None of these", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q6E,
  },
  musicQuestion: {
    question: "Which of these artists would you rather listen to?",
    questionType: "mc",
    options: [
      { option: "A. Drake", nextQuestion: "questionSeven" },
      {
        option: "B. Carrie Underwood",
        nextQuestion: "questionSeven",
      },
      { option: "C. Lizzo", nextQuestion: "questionSeven" },
      { option: "D. David Guetta", nextQuestion: "questionSeven" },
      { option: "E. Bob Dylan", nextQuestion: "questionSeven" },
      { option: "F. Metallica", nextQuestion: "questionSeven" },
      { option: "G. Miles Davis", nextQuestion: "questionSeven" },
      { option: "H. None of these", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q7,
  },
  moviesQuestion: {
    question: "Which of these movies would you rather watch?",
    questionType: "mc",
    options: [
      { option: "A. The Hangover", nextQuestion: "questionSeven" },
      {
        option: "B. The Godfather",
        nextQuestion: "questionSeven",
      },
      { option: "C. Star Wars", nextQuestion: "questionSeven" },
      { option: "D. The Fast & The Furious", nextQuestion: "questionSeven" },
      { option: "E. When Harry Met Sally", nextQuestion: "questionSeven" },
      { option: "F. Get Out", nextQuestion: "questionSeven" },
      { option: "G. 5B", nextQuestion: "questionSeven" },
      { option: "H. None of these", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q6B,
  },
  TVQuestion: {
    question: "Which of these shows would you rather watch?",
    questionType: "mc",
    options: [
      { option: "A. The Handmaid's Tale", nextQuestion: "questionSeven" },
      {
        option: "B. The Great British Bake Off",
        nextQuestion: "questionSeven",
      },
      { option: "C. American Horror Story", nextQuestion: "questionSeven" },
      { option: "D. Friends", nextQuestion: "questionSeven" },
      { option: "E. Love is Blind", nextQuestion: "questionSeven" },
      { option: "F. Planet Earth", nextQuestion: "questionSeven" },
      { option: "G. None of these", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q6D,
  },
  gamesQuestion: {
    question: "Which of these games would you rather play?",
    questionType: "mc",
    options: [
      { option: "A. Puzzles", nextQuestion: "questionSeven" },
      {
        option: "B. Monopoly",
        nextQuestion: "questionSeven",
      },
      { option: "C. Scrabble", nextQuestion: "questionSeven" },
      { option: "D. Poker", nextQuestion: "questionSeven" },
      { option: "E. Dungeons & Dragons", nextQuestion: "questionSeven" },
      { option: "F. None of these", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q6A,
  },
  videoGamesQuestion: {
    question: "Which of these games would you rather play?",
    questionType: "mc",
    options: [
      // { option: "A. Tetris", nextQuestion: "questionSeven" },
      {
        option: "A. Candy Crush",
        nextQuestion: "questionSeven",
      },
      { option: "B. Animal Crossing", nextQuestion: "questionSeven" },
      // { option: "D. Minecraft", nextQuestion: "questionSeven" },
      { option: "C. Fortnite", nextQuestion: "questionSeven" },
      { option: "D. The Legend of Zelda", nextQuestion: "questionSeven" },
      { option: "E. League of Legends", nextQuestion: "questionSeven" },
      { option: "F. MarioKart", nextQuestion: "questionSeven" },
      { option: "G. World Of Warcraft", nextQuestion: "questionSeven" },
      { option: "H. None Of These", nextQuestion: "questionSeven" },
    ],
    imageUrl: Q6C,
  },
  questionSeven: {
    question: "So how are you spending most of your free time these days?",
    questionType: "mc",
    options: [
      { option: "A. Hanging with my pets.", nextQuestion: "questionSeven" },
      {
        option: "B. Distracting myself with entertainment.",
        nextQuestion: "questionSeven",
      },
      {
        option: "C. Spending time with my family.",
        nextQuestion: "questionSeven",
      },
      { option: "D. Meditation or yoga.", nextQuestion: "questionSeven" },
      { option: "E. Pampering myself.", nextQuestion: "questionSeven" },
      { option: "F. Just relaxing.", nextQuestion: "questionSeven" },
      {
        option: "G. Getting stuff done around the house.",
        nextQuestion: "questionSeven",
      },
    ],
    imageUrl: Q9,
  },
};

export default Questions;
