import React from "react";
import QuestionOption from "./QuestionOption";
import QuestionInput from "./QuestionInput";

const Question = (props) => {
  const options = props.options ? (
    props.options.map((option, index) => (
      <QuestionOption
        questionNumber={props.questionNumber}
        question={props.question}
        option={option.option}
        key={index}
        next={props.next}
        nextQuestion={option.nextQuestion}
      />
    ))
  ) : (
    <QuestionInput
      questionNumber={props.questionNumber}
      question={props.question}
      nextQuestion="questionEight"
      next={props.next}
    />
  );

  return (
    <div className="question">
      <h1 className="questionHeader">{props.question}</h1>
      <div>{options}</div>
    </div>
  );
};

export default Question;
