import React from "react";
import "./QuestionOption.css";

const QuestionOption = props => {
  return (
    <div
      onClick={() => {
        props.next(props.questionNumber, props.option, props.nextQuestion);
      }}
      className="questionOption"
    >
      {props.option}
    </div>
  );
};

export default QuestionOption;
