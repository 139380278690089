const TitleBank = {
  questionOne: {
    YES: [],
    NO: [],
  },
  petsQuestion: {
    CATS: [
      "CCO,Chief Cat-Cuddling Officer",
      "CPO,Chief Pooper-Scooping Officer",
      "CLO,Chief Litterbox Officer",
      "CLO,Chief Lint-Rolling Officer",
      "CPO,Chief Purrrrrr Officer",
      "CCO,Chief Catnip Officer",
      "CFO,Chief Food-Dish-Filling Officer",
      "CCO,Chief Cat-Companion Officer",
      "CCO,Chief Cat-Toy-Tossing Officer",
      "CIO,Chief Indoor-Like-My-Indoor-Cat Officer",
      "CWO,Chief Watching-CATS-With-My-Cat Officer",
      "CMO,Chief My-Cat-Is-The-Boss Officer",
      "CFO,Chief Fur-Combing Officer",
      "CLO,Chief Laser-Pointer-Playing Officer",
      "CCO,Chief Cat-On-My-Keyboa$/;@( Officer",
    ],
    DOGS: [
      "CCO,Chief Canine-Cuddling Officer",
      "CDO,Chief Dog-Walking Officer",
      "CDO,Chief Dog-Grooming Officer",
      "CLO,Chief Lint-Rolling Officer",
      "CFO,Chief Frisbee-Flinging Officer",
      "CRO,Chief Ruff-Housing Officer",
      "CWO,Chief 'Who's-A-Good-Dog' Officer",
      "CDO,Chief 'Drop-It' Officer",
      "CSO,Chief 'Sit-Stay' Officer",
      "CFO,Chief Food-Dish-Filling Officer",
      "CTO,Chief Tug-Of-War-Winning Officer",
      "CFO,Chief Fur-Combing Officer",
      "CEO,Chief Ear-Scratching Officer ",
      "CTO,Chief The-Dog-Ate-My-Spreadsheet Officer",
      "CCO,Chief Canine Officer",
    ],
    REPTILE: [
      "CHO,Chief Herpetoculture Officer",
      "CSO,Chief Scale-Stroking Officer",
      "CCO,Chief Cage-Cleaning Officer",
      "CCO,Chief Cold-Blooded-Cuties Officer",
      "CSO,Chief Skin-Shedding-Pets Officer",
      "CMO,Chief 'My-Second-In-Command-Has-Scales' Officer",
      "CTO,Chief Turnt-Terrarrium Officer",
      "CHO,Chief Heatlamp-Adjusting Officer",
      "CSO,Chief Sunbathing-With-My-Reptile Officer",
    ],
    FISH: [
      "CTO,Chief Tank-Filling Officer",
      "CFO,Chief Fish-Feeding Officer",
      "CTO,Chief Tank-Cleaning Officer",
      "CSO,Chief Salinity-Monitoring Officer",
      "CHO,Chief Home-Aquarium Officer",
      "CFO,Chief Friends-With-Fins Officer",
      "CFO,Chief Fish-First-Mate Officer",
      "CAO,Chief All-Out-Aquarium Officer",
    ],
    BIRD: [
      "CFO,Chief Feathered-Friends Officer",
      "CIO,Chief Indoor-Bird-Watching Officer",
      "CPO,Chief 'Polly-Wants-A-What?' Officer",
      "CCO,Chief Cage-Cleaning Officer",
      "CNO,Chief Newspaper-Lining Officer",
      "CBO,Chief Beak-Booping Officer",
    ],
    OTHER: [
      "CPO,Chief Pet-Nuzzling Officer",
      "CFO,Chief Food-Dish-Filling Officer",
      "CPO,Chief Pet-Pic-Posting Officer",
      "CPO,Chief Pet-Playtime Officer",
      "CPO,Chief Pet-Parent Officer",
      "CAO,Chief Alternative-Pets Officer",
      "CPO,Chief 'Popular-Pets-Are-Overrated' Officer",
      "CPO,Chief Pet-Toy-Purchasing Officer",
      "CMO,Chief Meetings-Interrupted-By-My-Pet Officer",
    ],
  },
  questionThree: {
    TAKEOUTALLDAYBABY: [
      "CTO,Chief Take-Out-Ordering Officer",
      "CFO,Chief Frozen-Dinner Officer",
      "CMO,Chief Microwave Officer",
      "CLO,Chief Leftover-Loving Officer",
      "CSO,Chief Store-Bought Officer",
      "CCO,Chief Chip-Chomping Officer",
      "CIO,Chief Ice-Cream-Scooping Officer",
      "CSO,Chief Snacking Officer",
      "CWO,Chief Whole-Pint-Eating Officer",
      "CFO,Chief Fridge-Digging Officer",
      "CMO,Chief Midnight-Snack Officer ",
    ],
    ITSACHOREBUTILLDOIT: [
      "CTO,Chief Take-Out-Ordering Officer",
      "CFO,Chief Frozen-Dinner Officer",
      "CMO,Chief Microwave Officer",
      "CLO,Chief Leftover-Loving Officer",
      "CSO,Chief Store-Bought Officer",
      "CCO,Chief Chip-Chomping Officer",
      "CIO,Chief Ice-Cream-Scooping Officer",
      "CSO,Chief Snacking Officer",
      "CWO,Chief Whole-Pint-Eating Officer",
      "CFO,Chief Fridge-Digging Officer",
      "CMO,Chief Midnight-Snack Officer ",
    ],
    HAVEKIDS: {
      ITSFUNTOTRYNEWRECIPES: [
        "CMO,Chief Michelin-Starred-Mac-&-Cheese Officer",
        "CCO,Chief Cooking-With-The-Kids Officer",
        "CHO,Chief Homemade-Hot-Pocket Officer",
        "CVO,Chief Veggie-Disguising Officer",
        "CCO,Chief Cookie-Dough-Sampling Officer",
        "CGO,Chief Gourmet-Snack-Serving Officer",
        "CCO,Chief Clean-Plate-Club Officer",
        "CCO,Chief Cheese-Grilling Officer",
        "CBO,Chief Babysitting-The-Sous-Chef Officer",
        "CGO,Chief Gourmet-PB&J Officer",
      ],
      GETTINGMYCHEFON: [
        "CMO,Chief Michelin-Starred-Mac-&-Cheese Officer",
        "CCO,Chief Cooking-With-The-Kids Officer",
        "CHO,Chief Homemade-Hot-Pocket Officer",
        "CVO,Chief Veggie-Disguising Officer",
        "CCO,Chief Cookie-Dough-Sampling Officer",
        "CGO,Chief Gourmet-Snack-Serving Officer",
        "CCO,Chief Clean-Plate-Club Officer",
        "CCO,Chief Cheese-Grilling Officer",
        "CBO,Chief Babysitting-The-Sous-Chef Officer",
        "CGO,Chief Gourmet-PB&J Officer",
      ],
    },
    ITSFUNTOTRYNEWRECIPES: [
      "CMO,Chief Marinading-All-Day Officer",
      "CBO,Chief Banana-Bread-Baking Officer",
      "CCO,Chief Cookie-Crafting Officer",
      "CSO,Chief Stir-Fry-Sizzling Officer",
      "CSO,Chief Sous-Vide Officer",
      "CPO,Chief Pasta-From-Scratch Officer",
      "CEO,Chief Eggs-Any-Way Officer",
      "CFO,Chief Flambé Officer",
      "CAO,Chief Al-Dente Officer",
      "CBO,Chief Basting Officer",
      "CAO,Chief Au-Jus Officer",
      "CPO,Chief Pureé Officer",
      "CDO,Chief Dough-Kneading Officer",
      "CSO,Chief Soufflé Officer",
      "CCO,Chief Confit Officer",
      "CBO,Chief Broccoli-Blanching Officer",
      "CRO,Chief Roux-Recipe Officer",
      "CCO,Chief Caramelizing Officer",
      "CGO,Chief Garnish Officer",
      "CSO,Chief Seasoning-Savoring Officer",
      "CCO,Chief Creative-Cuisine Officer",
    ],
    GETTINGMYCHEFON: [
      "CMO,Chief Marinading-All-Day Officer",
      "CBO,Chief Banana-Bread-Baking Officer",
      "CCO,Chief Cookie-Crafting Officer",
      "CSO,Chief Stir-Fry-Sizzling Officer",
      "CSO,Chief Sous-Vide Officer",
      "CPO,Chief Pasta-From-Scratch Officer",
      "CEO,Chief Eggs-Any-Way Officer",
      "CFO,Chief Flambé Officer",
      "CAO,Chief Al-Dente Officer",
      "CBO,Chief Basting Officer",
      "CAO,Chief Au-Jus Officer",
      "CPO,Chief Pureé Officer",
      "CDO,Chief Dough-Kneading Officer",
      "CSO,Chief Soufflé Officer",
      "CCO,Chief Confit Officer",
      "CBO,Chief Broccoli-Blanching Officer",
      "CRO,Chief Roux-Recipe Officer",
      "CCO,Chief Caramelizing Officer",
      "CGO,Chief Garnish Officer",
      "CSO,Chief Seasoning-Savoring Officer",
      "CCO,Chief Creative-Cuisine Officer",
    ],
  },
  questionFour: {
    EXERCISEWHOSTHAT: [
      "CSO,Chief Sweatpants Officer",
      "CPO,Chief Pajamas-All-Day Officer",
      "CCO,Chief Couch Officer",
      "CJO,Chief Just-Woke-Up Officer",
      "CRO,Chief Remote-Control-Aerobics Officer",
      "CWO,CHief Working-From-Bed Officer",
      "CBO,Chief Binging-From-Bed Officer",
      "CSO,Chief Snooze-Button Officer",
      "CCO,Chief Cozy Officer",
      "CCO,Chief Comfy Officer",
      "CCO,Chief Cheat-Day-Every-Day Officer",
      "CNO,Chief Same-Shirt-Different-Day Officer",
      "CCO,Chief Couch-Cushion-Chic Officer",
      "CSO,Chief Sleeping-In Officer",
      "CFO,Chief Food-Coma Officer",
      "CSO,Chief Scrolling-Through-My-Phone Officer",
      "CCO,Chief Couch-Potato Officer",
      "CIO,Chief In-Bed-All-Day Officer",
      "CLO,Chief Lift-Remotes-Not-Weights Officer",
      "CBO,Chief Business-Shirt-Pajama-Pants Officer",
      "CBO,Chief Bed-To-Couch-Commute Officer",
    ],
    NOTMUCHATALL: [
      "CSO,Chief Sweatpants Officer",
      "CPO,Chief Pajamas-All-Day Officer",
      "CCO,Chief Couch Officer",
      "CJO,Chief Just-Woke-Up Officer",
      "CRO,Chief Remote-Control-Aerobics Officer",
      "CWO,CHief Working-From-Bed Officer",
      "CBO,Chief Binging-From-Bed Officer",
      "CSO,Chief Snooze-Button Officer",
      "CCO,Chief Cozy Officer",
      "CCO,Chief Comfy Officer",
      "CCO,Chief Cheat-Day-Every-Day Officer",
      "CNO,Chief Same-Shirt-Different-Day Officer",
      "CCO,Chief Couch-Cushion-Chic Officer",
      "CSO,Chief Sleeping-In Officer",
      "CFO,Chief Food-Coma Officer",
      "CSO,Chief Scrolling-Through-My-Phone Officer",
      "CCO,Chief Couch-Potato Officer",
      "CIO,Chief In-Bed-All-Day Officer",
      "CLO,Chief Lift-Remotes-Not-Weights Officer",
      "CBO,Chief Business-Shirt-Pajama-Pants Officer",
      "CBO,Chief Bed-To-Couch-Commute Officer",
    ],
    HAVEKIDS: {
      MORETHANIUSEDTO: [
        "CBO,Chief Benchpress-The-Kids Officer",
        "CPO,Chief Piggyback-Pushups Officer",
        "CBO,Chief Babysitting-While-Benchpressing Officer",
        "CKO,Chief Kiddie-Curling Officer",
        "CLO,Chief Little-One-Lunging Officer",
        "CWO,Chief Weight-Lifting-Little-Ones Officer",
        "CIO,Chief Indoor-Parkour Officer",
      ],
      MAKINGGAINSEVERYDAY: [
        "CBO,Chief Benchpress-The-Kids Officer",
        "CPO,Chief Piggyback-Pushups Officer",
        "CBO,Chief Babysitting-While-Benchpressing Officer",
        "CKO,Chief Kiddie-Curling Officer",
        "CLO,Chief Little-One-Lunging Officer",
        "CWO,Chief Weight-Lifting-Little-Ones Officer",
        "CIO,Chief Indoor-Parkour Officer",
      ],
    },
    MORETHANIUSEDTO: [
      "CIO,Chief Inside-Exercise Officer",
      "CNO,Chief Never-Skip-Leg-Day Officer",
      "CPO,Chief Planking Officer",
      "CIO,Chief Indoor-Parkour Officer",
      "CCO,Chief Crossfit-At-Home Officer",
      "CHO,Chief Hallway-Pilates Officer",
      "CIO,Chief Indoor-Cycling Officer",
      "CGO,Chief Gains-All-Day Officer",
      "CPO,Chief Push-Up-Perfecting Officer",
      "CDO,Chief Doorway-Pull-Ups Officer",
      "CSO,Chief Stair-Stepping Officer",
      "CPO,Chief Pull-Up-Perfecting Officer",
      "CPO,Chief Power-Walking-In-Place Officer",
      "CAO,Chief Apartment-Air-Squatting Officer",
      "CDO,Chief Dedicated-To-Reps Officer",
      "CFO,Chief Free-Weight Officer",
      "CHO,Chief Home-Workout Officer",
      "CHO,Chief Home-Push-Ups Officer",
      "CAO,Chief Apartment-Aerobics Officer",
      "CUO,Chief Up-And-Down-The-Stairs Officer",
      "CNO,Chief 'No-Pain-No-Gain' Officer",
      "CBO,Chief Bedroom-Shredroom Officer",
      "CHO,Chief Home-Workout-Hammies Officer",
      "CYO,Chief Yoga-Poser Officer",
      "CTO,Chief 'Train-Insane-Or-Remain-The-Same' Officer",
      "CQO,Chief Quads-In-The-Kitchen Officer",
      "CFO,Chief From-Workout-To-Work-Call Officer",
    ],
    MAKINGGAINSEVERYDAY: [
      "CIO,Chief Inside-Exercise Officer",
      "CNO,Chief Never-Skip-Leg-Day Officer",
      "CPO,Chief Planking Officer",
      "CIO,Chief Indoor-Parkour Officer",
      "CCO,Chief Crossfit-At-Home Officer",
      "CHO,Chief Hallway-Pilates Officer",
      "CIO,Chief Indoor-Cycling Officer",
      "CGO,Chief Gains-All-Day Officer",
      "CPO,Chief Push-Up-Perfecting Officer",
      "CDO,Chief Doorway-Pull-Ups Officer",
      "CSO,Chief Stair-Stepping Officer",
      "CPO,Chief Pull-Up-Perfecting Officer",
      "CPO,Chief Power-Walking-In-Place Officer",
      "CAO,Chief Apartment-Air-Squatting Officer",
      "CDO,Chief Dedicated-To-Reps Officer",
      "CFO,Chief Free-Weight Officer",
      "CHO,Chief Home-Workout Officer",
      "CHO,Chief Home-Push-Ups Officer",
      "CAO,Chief Apartment-Aerobics Officer",
      "CUO,Chief Up-And-Down-The-Stairs Officer",
      "CNO,Chief 'No-Pain-No-Gain' Officer",
      "CBO,Chief Bedroom-Shredroom Officer",
      "CHO,Chief Home-Workout-Hammies Officer",
      "CYO,Chief Yoga-Poser Officer",
      "CTO,Chief 'Train-Insane-Or-Remain-The-Same' Officer",
      "CQO,Chief Quads-In-The-Kitchen Officer",
      "CFO,Chief From-Workout-To-Work-Call Officer",
    ],
  },
  questionFive: {
    GAMES: [
      "CDO,Chief Dice-Rolling Officer",
      "CCO,Chief 'Checkmate!' Officer",
      "CGO,Chief Gameboard Officer",
      "CCO,Chief Candlestick-Wielding Officer",
      "CBO,Chief Board-Game Officer",
      "CGO,Chief Gamepiece-Moving Officer",
      "CKO,Chief 'King-Me' Officer",
      "CRO,Chief Rulebook-Reading Officer",
      "CWO,Chief Winning-Streak Officer",
    ],
    MUSIC: [
      "CPO,Chief Playlist-Curating Officer",
      "CHO,Chief Head-Bopping Officer",
      "CJO,Chief Jamming Officer",
      "CSO,Chief Shower-Singing Officer",
      "CAO,Chief Anything-Is-A-Microphone Officer",
      "CHO,Chief Headphones-Always-In Officer",
      "CNO,Chief New-Song-Discovering Officer",
      "CAO,Chief Air-Guitar Officer",
      "CRO,Chief Record-Spinning Officer",
      "CMO ,Chief Mood-Music Officer",
      "CPO,Chief Playlist-On-Repeat Officer",
      "CDO,Chief Discography-Devouring Officer",
    ],
    VIDEOGAMES: [
      "CGO,Chief Gameplay Officer",
      "CGO,Chief Gaming Officer",
      "CLO,Chief Level-Up Officer",
      "CBO,Chief Button-Mashing Officer",
      "CFO,Chief Final-Boss Officer",
      "CHO,Chief High-Score Officer",
      "CHO,Chief Hot-Streak Officer",
      "CDO,Chief Double-Jumping Officer",
      "CBO,Chief Bonus-Stage Officer",
      "CCO,Chief Co-Op Mode Officer",
      "CPO,Chief Power-Up Officer",
      "CCO,Chief Cheat-Code Officer",
    ],
    TV: [
      "CRO,Chief Remote-Control Officer",
      "CCO,Chief Channel-Surfing Officer",
      "CBO,Chief Binge-Watching Officer",
      "CJO,Chief Just-One-More-Episode Officer",
      "CHO,Chief Hulu-Binging Officer",
      "CSO,Chief Show-Streaming Officer",
      "CCO,Chief Couch-Warming Officer",
      "CEO,Chief Episodic-Entertainment Officer",
      "CSO,Chief Season-Savoring Officer",
      "CRO,Chief Rerun Officer",
      "CFO,Chief Finished-That-Series-In-A-Week Officer",
    ],
    BOOKS: [
      "CPO,Chief Page-Turner Officer",
      "CBO,Chief Bookworm Officer",
      "CBO,Chief Book-Binging Officer",
      "CDO,Chief Dog-Ear Officer",
      "CBO,Chief Book-Bending Officer",
      "CFO,Chief First-Edition Officer",
      "CPO,Chief Paper-Back Officer",
      "CCO,Chief Cover-Cracking Officer",
      "CFO,Chief Finished-That-Series-In-A-Week Officer",
      "CBO,Chief Bibliophile Officer",
      "CCO,Chief Cover-To-Cover Officer",
      "CJO,Chief Just-One-More-Chapter Officer",
      "CCO,Chief Couldn't-Put-It-Down Officer",
    ],
    SOCIALMEDIA: [
      "CEO,Chief Endlessly-Scrolling Officer",
      "CLO,Chief Live-Stream-Loving Officer",
      "CHO,Chief #StayHome-Sharing Officer",
      "CHO,Chief #Home-Hashtagging Officer",
      "CSO,Chief Social-Sharing Officer",
      "CPO,Chief Pet-Pic-Posting Officer",
      "CSO,Chief Story-Swiping Officer",
      "CFO,Chief Feed-Scrolling Officer",
      "CFO,Chief Food-Blog-Following Officer",
      "CYO,Chief 'You're-All-Caught-Up' Officer",
      "CFO,Chief Feed-Refreshing Officer",
      "CSO,Chief Social-Good-Social-Posting Officer",
      "CIO,Chief Internet-Rabbithole Officer",
      "CIO,Chief #InsideInspiration Officer",
      "CYO,Chief YouTube-Vortex Officer",
      "CCO,Chief Character-Counting Officer",
      "CTO,Chief Twitter-Threading Officer",
      "CIO,Chief Instagram-Filter-Using Officer",
    ],
    MOVIES: [
      "CHO,Chief Homemade-Popcorn Officer",
      "CCO,Chief Couch-Cinema Officer",
      "CFO,Chief Film-Buff-From-Home Officer",
      "CCO,Chief Cult-Classics Officer",
      "CMO,Chief Movie-Marathoning Officer",
      "CMO,Chief Movie-Quoting Officer",
      "CHO,Chief Home-Theater Officer",
      "CCO,Chief Couch-Critic Officer",
      "CCO,Chief Couch-Camera-Action! Officer",
      "CWO,Chief Wax-On-Wax-Officer",
      "CTO,Chief 'There's-No-Place-Like-Home' Officer",
    ],
  },
  TVQuestion: {
    PLANETEARTH: [
      "CDO,Chief Documentary-Devouring Officer",
      "CCO,Chief Couch-Cinema-Verite Officer",
      "CCO,Chief Content-For-A-Cause Officer",
      "CEO,Chief Educational-Entertainment Officer",
      "CLO,Chief Lounge-'N-Learn Officer",
    ],
    LOVEISBLIND: [
      "CBO,Chief Brain-Candy-Content Officer",
      "CFO,Chief Feel-Good-Content Officer",
      "CTO,Chier Turn-My-Brain-Off-Content Officer",
      "CRO,Chief Reality-Show-Binging Officer",
      "CRO,Chief Rose-Ceremony Officer",
      "CVO ,Chief Voted-Off-The-Island Officer",
    ],
    THEHANDMAIDSTALE: [
      "CBO,Chief Blessed-Be-The-Fruit Officer",
      "CPO,Chief Plot-Twist-Predicting Officer",
      "CTO,Chief TV-Drama-Loving Officer",
      "CGO,Chief *Gasp* Officer",
      "CDO,Chief Dramatic-Tension Officer",
    ],
    AMERICANHORRORSTORY: [
      "CJO,Chief Jump-Scare Officer",
      "CDO,Chied 'Don't-Go-In-There' Officer",
      "CNO,Chief Nightlight-Inducing-Entertainment Officer",
      "CEO,Chief Eerie-Entertainment Officer",
      "CFO,Chief Fear-Inducing-Films Officer",
    ],
    FRIENDS: [
      "CSO,Chief Sitcom Officer",
      "CCO,Chief Comfort-Comedy Officer",
      "CLO,Chief Living-Room-LOLing Officer",
      "CCO,Chief Comedy-Connoisseuring Officer",
      "CAO,Chief Awkward-Humor Officer",
      "CSO,Chief Seen-This-Episode-100-Times Officer",
      "CLO,Chief Laugh-Track Officer",
      "CPO,Chief Punch-Line-Predicting Officer",
    ],
    THEGREATBRITISHBAKINGSHOW: [
      "CJO,Chief Judge-Judging Officer",
      "CCO,Chief Competition-Show Officer",
      "CBO,Chief Bake-Off-Binging Officer",
      "CLO,Chief Lighthearted-Content Officer",
      "CFO,Chief Feel-Good-Content Officer",
    ],
  },
  musicQuestion: {
    // CELINEDION: [
    //   "CSO,Chief Song-Belting Officer",
    //   "CPO,Chief Pitch-Perfecting Office",
    //   "CKO,Chief Key-Change Officer",
    //   "CBO,Chief Ballad-Blasting Officer",
    //   "CSO,Chief Songs-That-Make-Me-Cry Officer",
    // ],
    LIZZO: [
      "CDO,Chief Dancing-Out-My-Feelings Officer",
      "CDO,Chief Dancing-In-The-Mirror Officer",
      "CSO,Chief Songs-With-Soul Officer",
      "CFO,Chief Flute-Solo-Savoring Officer",
      "CRO,Chief RnB-Rockin' Officer",
      "CDO,Chief Dance-All-Day Officer",
    ],
    BOBDYLAN: [
      "CNO,Chief Nobel-Prize-Playlist Officer",
      "CTO,Chief 'The-Times-They-Are-A-Changin'' Officer",
      "CFO,Chief Fondness-For-Folk Officer",
      "CTO,Chief 'Three-Chords-And-The-Truth' Officer",
      "CHO,Chief Harmonica-Solo-Savoring Officer",
      "CRO,Chief Rock-n-Roll Officer",
      "COO,Chief Oldie-But-Goodie Officer",
      "CBO,Chief Banjo-Bumpin' Officer",
    ],
    DRAKE: [
      "CFO,Chief Freestyle-Flowing Officer",
      "CBO,Chief Beat-Dropping Officer",
      "CRO,Chief Rhythm-And-Rhyme Officer",
      "CHO,Chief Hip-Hop Officer",
      "CBO,Chief Beatbox Officer",
      "CIO,Chief In-My-Feelings Officer",
      "CSO,Chief Skrrt-Skrrt Officer",
    ],
    CARRIEUNDERWOOD: [
      "CTO,Chief Twang Officer",
      "CCO,Chief Country Officer",
      "CAO,Chief Americana Officer",
      "CHO ,Chief Horses-In-The-Back Officer",
      "CRO,Chief Rockabilly Officer",
      "CBO,Chief Banjo-Blasting Officer",
      "CBO,Chief Bluegrass-Blasting Officer",
      "CHO,Chief Howdy-Y'all Officer",
      "CHO,Chief Honky-Tonk Officer",
    ],
    DAVIDGUETTA: [
      "CBO,Chief Bass-Drop Officer",
      "CEO,Chief EDM Officer",
      "CTO,Chief Turntable Officer",
      "COO,Chier Oontz-Oontz Officer",
      "CFO,Chief Fist-Pump Officer",
      "CRO,Chief Raving-At-Home Officer",
      "CEO,Chief Electronic Officer",
      "CWO,Chief Waiting-For-The-Bass-Drop Officer",
    ],
    // THEBEATLES: [
    //   "CIO,Chief 'I-Am-The-Walrus' Officer",
    //   "CRO,Chief 'Ringo-Is-Underrated' Officer",
    // ],
    METALLICA: [
      "CHO,Chief Head-Banging Officer",
      "CGO,Chief Guitar-Solo Officer",
      "CHO,Chief Heavy-Metal Officer",
      "COO,Chief One-Person-Mosh-Pit Officer",
    ],
    MILESDAVIS: [
      "CRO,Chief Rhythm-And-Blues Officer",
      "CBO,Chief Bebop Officer",
      "CJO,Chief Jazz-Jams Officer",
      "CSO,Chief Sax-Solo Officer",
      "CSO,Chief 'skeep-beep-de-bop-bop' Officer",
    ],
    NONEOFTHESE: [
      "CPO,Chief Playlist-Curating Officer",
      "CHO,Chief Head-Bopping Officer",
      "CJO,Chief Jamming Officer",
      "CSO,Chief Shower-Singing Officer",
      "CAO,Chief Anything-Is-A-Microphone Officer",
      "CHO,Chief Headphones-Always-In Officer",
      "CNO,Chief New-Song-Discovering Officer",
      "CAO,Chief Air-Guitar Officer",
      "CRO,Chief Record-Spinning Officer",
      "CMO ,Chief Mood-Music Officer",
      "CPO,Chief Playlist-On-Repeat Officer",
      "CDO,Chief Discography-Devouring Officer",
    ],
  },
  booksQuestion: {
    LORDOFTHERINGS: [
      "CSO,Chief Speculative-Fiction Officer",
      "CMO,Chief Magic-And-Might Officer",
      "CAO,Chief Android-Assessing Oficer",
      "CSO,Chief Sword-And-Sorcery Officer",
      "CEO,Chief Once-Upon-A-Time Officer",
    ],
    IT: [
      "COO,Chief Over-1000-Pages Officer",
      "CSO,Chief Stephen-King-Loving",
      "CTO,Chief Things-That-Go-Bump-In-The-Night Officer",
      "CNO,Chief Nightlight-Needed-Novels Officer",
      "CGO,Chief Ghost-Story-Savant Officer",
    ],
    BECOMINGMICHELLEOBAMA: [
      "CBO,Chief Biography-Binging Officer",
      "CTO,Chief True-Story Officer",
      "CLO,Chief Lounge-'n-Learn Officer",
      "CTO,Chiet 'The-Secret-Story-Of...' Officer",
      "CRO,Chief Reading-About-Real-People Officer",
    ],
    CRAZYRICHASIANS: [
      "CVO,Chief Virtual-Bookclub Officer",
      "CPO,Chief Pop-Fiction Officer",
      "CAO,Chief At-Home-Beach-Read Officer",
      "CRO ,Chief Romance-Reading Officer",
      "CFO,Chief Finished-That-Book-In-One-Sitting Officer",
    ],
    MURDERONTHEORIENTEXPRESS: [
      "CAO,Chief Agatha-Christie Officer",
      "CNO,Chief Nancy-Drew Officer",
      "CMO,Chief Murder-Mystery Officer",
      "CWO,Chief Whodunnit Officer",
      "CCO,Chief Clue-Collecting Officer",
    ],
  },
  gamesQuestion: {
    PUZZLES: [
      "CPO,Chief Puzzle-Solving Officer",
      "C1O,Chief 1000-Piece-Jigsaw Officer",
      "CFO,Chief Final-Puzzle-Piece-Placing Officer",
      "CSO,Chief 'Start-With-The-Corners' Officer",
      "CSO,Chief Solving-Puzzles-Without-Looking-At-The-Box Officer",
    ],
    MONOPOLY: [
      "CFO,Chief Four-Person-Property-Trade Officer",
      "CDO,Chief Do-Not-Pass-Go Officer",
      "CHO,Chief Hotel-Building Officer",
      "CSO,Chief Strategy-Game-Slaying Officer",
      "CAO,Chief Always-Playing-Board-Games-To-Win Officer",
    ],
    SCRABBLE: [
      "CTO,Chief Triple-Word-Score Officer",
      "CWO,Chief Word-Game-Winning Officer",
      "CLO,Chief Letter-Tile-Arranging Officer",
      "CVO,Chief Voluminous-Vocabulary Officer",
      "CLO,Chief Letter-Tile-Loving Officer",
    ],
    POKER: [
      "CCO,Chief Card-Counting Officer",
      "CRO,Chief Royal-Flush Officer",
      "CDO,Chief Deck-Shuffling Officer",
      "CHO,Chief Hold-'em Officer",
      "CCO,Chief Card-Slinging Officer",
      "CPO,Chief Pocket-Aces Officer",
    ],
    DD: [
      "CTO,Chief Tabletop-Gaming Officer",
      "CGO,Chief GM-Vaquishing Officer",
      "CRO,Chief RPG Officer",
      "CIO,Chief Initiative-Rolling Officer",
      "CCO,Chief Critical-Fail-Rolling Officer",
    ],
  },
  videoGamesQuestion: {
    FORTNITE: [
      "CPO,Chief Pwnage Officer",
      "CMO,Chief Multi-Player Pwning Officer",
      "CBO,Chief Battle-Royale-Gaming Officer",
      "CBO,Chief Build-'N-Blast Officer",
      "CLO,Chief Loot-Drop-Locating Officer",
    ],
    THELEGENDOFZELDA: [
      "CAO,Chief Action-Adventure Officer",
      "CRO,Chief RPG Officer",
      "CRO,Chief Rescue-The-Princess Officer",
      "CHO,Chief Hero-Of-Hyrule Officer",
      "COO,Chief Open-World-Gaming Officer",
    ],
    MARIOKART: [
      "CMO,Chief Mario-Mushroom-Eating Officer",
      "CPO,Chief Party-Game-Playing Officer",
      "CRO,Chief Racing-From-My-Couch Officer",
      "CRO,Chief Rainbow-Road Officer",
      "CVO,Chier Virtual-Pedal-To-The-Virtual-Metal Officer",
    ],
    LEAGUEOFLEGENDS: [
      "CFO,Chief Fail-Flashing Officer",
      "COO,Chief Out-Smiting Officer",
      "CEO,Chief E-Sports-Enthusiast Officer",
      "CMO,Chief Minion-Farming Officer",
      "CMO,Chief MOBA-Playing Officer",
    ],
    ANIMALCROSSING: [
      "CSO,Chief Social-Simulation Officer",
      "CAO,Chief Anthropomorphic-Animals Officer",
      "CEO,Chief Escapist-Entertainment Officer",
      "CVO,Chief Visiting-Friends'-Islands Officer",
      "CLO,Chief Low-Stress-Gaming Officer",
    ],
    WORLDOFWARCRAFT: [
      "CNO,Chief Never-AFK Officer",
      "CLO,Chief Looking-For-Group Officer",
      "CFO,Chief For-The-Horde Officer",
      "CFO,Chief For-The-Alliance Officer",
      "CSO,Chief Social-Simulation Officer",
      "CDO ,Chief Dungeon-Clearing Officer",
      "CLO,Chief 'Leeeeeeroy Jenkins' Officer",
      "CHO,Chief Hogger-Slaying Officer",
      "CMO,Chief Murloc-Loving Officer",
    ],
    CANDYCRUSH: [
      "CBO,Chief Brain-Candy-Gaming Officer",
      "CMO,Chief Mobile-Game-Binging Officer",
      "CCO,Chief Candy-Crush-Crushing Officer",
      "CTO,Chief Turn-My-Brain-Off-Content Officeer",
      "CAO,Chief Arcade-In-My-Pocket Officer",
    ],
    PACMAN: [
      "CAO,Chief Arcade-Game Officer",
      "CQO,Chief Quarter-Based-Gaming Officer",
      "CCO,Chief Cheat-Code Officer",
      "C8O,Chief 8-Bit Games Officer ",
      "C8O,Chief 8-Bits-No-Problems Officer",
    ],
  },
  moviesQuestion: {
    "5B": [
      "CDO,Chief Documentary-Devouring Officer",
      "CCO,Chief Couch-Cinema-Verite Officer",
      "CCO,Chief Content-For-A-Cause Officer",
      "CEO,Chief Educational-Entertainment Officer",
      "CLO,Chief Lounge-'N-Learn Officer",
    ],
    THEHANGOVER: [
      "CCO,Chief Couch-Comedy Officer",
      "CLO,Chief Living-Room-LOLing Officer",
      "CPO,Chief Punch-Line-Predicting Officer",
      "CAA,Chief Absurdist-Humor Officer",
      "CAA,Chief Awkward-Humor Officer",
      "CSO,Chief Seen-This-Movie-100-Times Officer",
    ],
    // WHENHARRYMETSALLY: [
    //   "CYO,Chief 'You-Complete-Me' Officer",
    //   "CYO,Chief 'You-Had-Me-At-Hello' Officer",
    //   "CRO,Chief Rom-Com-Relishing Officer",
    //   "CWO,Chief Will-They-Won't-They Officer",
    //   "CMO,Chief Meet-Cute Officer",
    //   "CNE,Chief Nora-Ephron Enthusiast",
    // ],
    STARWARS: [
      "CMO,Chief 'May-The-Force-Be-With-You' Officer",
      "CLO,Chief Lightspeed Officer",
      "CPO,Chief 'Punch-It!' Officer",
      "CSO,Chief Starship Officer",
      "CVO,Chief VFX-Loving Officer",
      "CCO,Chief CGI-Critiquing Officer",
    ],
    THEFASTTHEFURIOUS: [
      "CCO,Chief Car-Chase Officer",
      "CIO,Chief 'I'll Be Back' Officer",
      "CSO,Chief 'Shaken, Not Stirred' Officer",
      "CEE,Chief Explosion Enthusiast",
      "CLO,Chief Lense-Flare-Loving Officer",
      "COO,Chief Over-The-Top-Action Officer",
    ],
    THEGODFATHER: [
      "CCO,Chief Corleone Officer",
      "CDO,Chief Dramatic-Brooding Officer",
      "CTO,Chier 'Take-The-Canoli' Officer",
      "Chief 'The-Sequel-Is-Better-Than-The-Original' Officer ",
      "CMO,Chief Marveling-At-Mobster-Movies Officer",
      "CCO,Chief Classic-Movie Officer",
      "CBO,Chiet Black-&-White-Film Officer",
      "CAO,Chief All-Time-Great-Movies Officer",
      "CFO,Chief Films-Shot-On-Actual-Film Officer",
      "CCO,Chief Criterion-Collection-Combing Officer",
    ],
    GETOUT: [
      "CJO,Chief Jump-Scare Officer",
      "CDO,Chief 'Don't-Go-In-There' Officer",
      "CNO,Chief Nightlight-Inducing-Entertainment Officer",
      "CHO,Chief Horror-Movie-Loving Officer",
      "CIO,Chief 'I-Can't-Watch' Officer",
    ],
    // CASABLANCA: [
    //   "CCO,Chief Classic-Movie Officer",
    //   "CBO,Chiet Black-&-White-Film Officer",
    //   "CAO,Chief All-Time-Great-Movies Officer",
    //   "CFO,Chief Films-Shot-On-Actual-Film Officer",
    //   "CCO,Chief Criterion-Collection-Combing Officer",
    // ],
    // GREASE: [
    //   "CSO,Chief Sing-A-Long Officer",
    //   "CTO,Chief 'Tell-Me-More-Tell-Me-More' Officer",
    //   "CSO,Chief Showtune-Belting Officer",
    //   "CSO,Chief Soundtrack-On-Repeat Officer",
    //   "CMO,Chief Movie-Musical-Melody-Mastering Officer",
    // ],
  },
  questionEight: {
    JUSTRELAXING: [
      "CSO,Chief Sweatpants Officer",
      "CPO,Chief Pajamas-All-Day Officer",
      "CCO,Chief Couch Officer",
      "CJO,Chief Just-Woke-Up Officer",
      "CRO,Chief Remote-Control-Aerobics Officer",
      "CWO,CHief Working-From-Bed Officer",
      "CBO,Chief Binging-From-Bed Officer",
      "CSO,Chief Snooze-Button Officer",
      "CCO,Chief Cozy Officer",
      "CCO,Chief Comfy Officer",
      "CCO,Chief Cheat-Day-Every-Day Officer",
      "CNO,Chief Same-Shirt-Different-Day Officer",
      "CCO,Chief Couch-Cushion-Chic Officer",
      "CSO,Chief Sleeping-In Officer",
      "CFO,Chief Food-Coma Officer",
      "CSO,Chief Scrolling-Through-My-Phone Officer",
      "CCO,Chief Couch-Potato Officer",
      "CIO,Chief In-Bed-All-Day Officer",
      "CLO,Chief Lift-Remotes-Not-Weights Officer",
      "CBO,Chief Business-Shirt-Pajama-Pants Officer",
      "CBO,Chief Bed-To-Couch-Commute Officer",
    ],
    MEDITATIONORYOGA: [
      "CMO,Chief Mindfulness Officer",
      "CDO,Chief Downward-Dog Officer",
      "CNO,Chief Namaste Officer",
      "CMO,Chief Midday-Mantra Officer",
      "CSO,Chief Savasana Officer",
      "CWO,Chief 'WoooSahh' Officer",
      "CZO,Chief Zen Officer",
      "CBO,Chief Being-Present Officer",
      "COO,Chief 'Ohm' Officer",
      "CMO,Chief Morning-Mantra Officer",
      "CCO,Chief Chakra Officer",
      "CDO,Chief Dharma Officer",
      "CCO,Chief Calming-Crystals Officer",
      "CSSO,Chief Sage-Smudging Officer",
      "CCO,Chief Connecting-With-My-Inner-Self Officer",
      "CSO,Chief Self-Care Officer",
      "CTO,Chief Tarot-Reading Officer",
      "CVO,Chief Vinyasa-Flow Officer",
      "CCO,Chief Childs-Pose Officer",
      "CEO,Chief 'Empty-Your-Mind' Officer",
      "CZO,Chief Zoning-Out Officer",
    ],
    PAMPERINGMYSELF: [
      "CSO,Chief Skincare-Routine Officer",
      "CLO,Chief Let-Your-Hair-Down Officer",
      "CSO,Chief Spa-Day-All-Day Officer",
      "CDO,Chief Draw-The-Bath Officer",
      "COO,Chief One-More-Bite Officer",
      "CFO,Chief Finally-Getting-Enough-Sleep Officer",
      "CTO,Chief Tub-Soaking Officer",
      "CHO,Chief Hair-Mask Officer",
      "CHO,Chief Home-Mani-Pedi Officer",
      "CMO,Chief Moisturizing Officer",
      "CBO,Chief Bubblebath Officer",
      "CMO,Chief Massage-Roller Officer",
      "CSO,Chief Sleeping-In-For-The-First-Time-Ever Officer",
      "CEO,Chief Epsom-Salt Officer",
      "CSO,Chief Scrub-A-Dub Officer",
      "CEO,Chief Exfoliation Officer",
      "CSO,Chief Sheet-Mask Officer",
      "CCO,Chief Chocolate Officer",
      "CGO,Chief Got-All-My-Favorite-Things Officer",
      "CDO,Chief Decadant-Dessert Officer",
      "CSO,Chief Self-Care Officer",
    ],
    GETTINGSTUFFDONEINANDAROUNDTHEHOUSE: [
      "CTO,Chief To-Do-List-Crossing-Officer",
      "CGO,Chief Green-Thumb Officer",
      "CHO,CHief Home-Horticulture Officer",
      "CFO,Chief Finally-Getting-Around-To-It Officer",
      "CSO,Chief Smelling-The-Homegrown-Roses Officer",
      "CSO,Chief Seed-Sowing Officer",
      "CBO,Chief Backyard-Basil Officer",
      "CDO,Chief Dust-Buster Officer",
      "CTO,Chief Tidying-Up Officer",
      "CSO,Chief 'Does-It-Spark-Joy?' Officer",
      "CDO,Chief DIY Officer",
      "CVO,Chief Vacuum-Vigilante Officer",
      "CPO,Chief Plant-Whispering Officer",
      "CHO,Chief Houseplant Officer",
      "COO,Chief Organizing Officer",
      "CHO,Chief Homegrown-Herbs Officer",
      "CTO,Chief Toolbox-Toting Officer",
      "COO,Chief Odd-Job Officer",
      "CSO,Chief Semi-Pro-Plumbing Officer",
      "CWO,Chief Wax-On-Wax-Officer",
    ],
  },

  haveKidsQuestion: {
    INFANT: [
      "CDO,Chief Diaper-Duty Officer",
      "CBO,Chief Bjorn-Bouncing Officer",
      "CNO,Chief Nap-Time Officer",
      "CSO,Chief Stroller-Pushing Officer",
      "CSO,Chief Swaddle Officer",
      "CUO,Chief Up-All-Night Officer",
      "CPO,Chief Peek-A-Boo Officer",
      "CPO,Chief Pacifier Officer",
      "CFO,Chief Formula-Feeding Officer",
      "CLO,Chief Laundry-Loading Officer",
    ],
    TODDLER: [
      "CFO,Chief Fort-Making Officer",
      "CMO,Chief Make-Believe Officer",
      "CHO,Chief Home-Schooling Officer",
      "CBO,Chief Bedtime-Story Officer",
      "CCO,Chief Child-Wrangling Officer",
      "CQO,Chief Quiet-Time Officer",
      "CNO,Chief Nap-Time Officer",
      "CFO,Chief Finger-Painting Officer",
      "CAO,Chief Arts-And-Crafts Officer",
      "CSO,Chief Show-And-Tell Officer",
      "CHO ,Chief Hide-And-Seek Officer",
      "CHO,Chief Hygiene-Police-Officer",
      "CLO,Chief Laundry-Loading Officer",
      "CSO,Chief Snack-Time Officer",
      "CBO,Chief Boo-Boo-Fixing Officer",
      "CPO,Chief 'Please-Not-Elmo-Again' Officer",
      "CPO,Chief 'Please-Not-Baby-Shark-Again' Officer",
      "CDO,Chief Dishwashing Officer",
      "CPO,Chief Piggyback-Ride Officer",
      "CBO,Chief Being-Interrupted-1000-Times Officer",
    ],
    TWEEN: [
      "CDO,Chief Dishwashing Officer",
      "CTO,Chief TikToking Officer",
      "CCO,Chief Complaint Officer",
      "CSO,Chief Screen-Time-Policing Officer",
      "CSO,Chief Social-Media-Monitoring Officer",
      "CRO,Chief 'Read-A-Book!' Officer",
      "CDO,Chief Distance-Learning Officer",
      "CHO,Chief Home-Schooling Officer",
      "CLO,Chief Laundry-Loading Officer",
      "CFO,Chief Fam-Time Officer",
      "CCO,Chief Chore Officer",
    ],
    TEEN: [
      "CWO,Chief WiFi-Fixing Officer",
      "CFO,Chief Family-Movie-Night Officer",
      "CFO,Chief Family-Game-Night Officer",
      "CKO,Chief Keeping-The-Fridge-Stocked Officer",
      "CTO,Chief 'Turn-That-Down!' Officer",
      "CDO,Chief 'Do-Your-Chores' Officer",
      "CLO,Chief Laundry-Loading Officer",
      "CDO,Chief Dishwashing Officer ",
      "CHO,Chief Home-Schooling Officer",
      "CRO,Chief 'Read-A-Book!' Officer",
    ],
  },
};

export default TitleBank;
